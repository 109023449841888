<template>
  <div class="transaction-table">
    <div class="transaction-header-container">
      <h2 class="header-title">Transaction</h2>
      <div class="tabs-list">
        <div :class="{active:activeTabIndex===0}" @click="activeTabIndex=0" class="item ">{{ this.$tc('wallet') }}</div>
        <div :class="{active:activeTabIndex===1}" @click="activeTabIndex=1" class="item">{{ this.$tc('stake') }}</div>
        <div :class="{active:activeTabIndex===2}" @click="activeTabIndex=2" class="item">{{ this.$tc('hedgeFund') }}</div>
      </div>
    </div>
    <v-data-table
        :headers="headers"
        :items="activeTabTransaction"
        hide-default-footer
        class="elevation-1"
    >
      <template v-slot:item.status="{ item }">
        <div class="table-flex">
          <img :src="item.icon" alt="">
          <span class="title">{{ item.status }}</span>
        </div>
      </template>
      <template v-slot:item.amount="{ item }">
        <div class="table-flex">
          <span class="title">{{ item.amount }} {{ item.balanceType }}</span>
        </div>
      </template>
      <!--      <template v-slot:item.type="{ item }">-->
      <!--        <div class="table-flex">-->
      <!--          <img src="../../assets/images/radiation_clock.png" alt="">-->
      <!--          <span class="title">{{ item.transactionType }}</span>-->
      <!--        </div>-->
      <!--      </template>-->
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      activeTabIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      walletTransaction: 'wallet/getWalletTransaction',
      stakeTransaction: 'stake/getStakeTransaction',
      investment: 'funds/getInvestment',
    }),
    activeTabTransaction() {
      return this.activeTabIndex === 0 ? this.walletTransaction : this.activeTabIndex === 1 ? this.stakeTransaction : this.investment
    },
    headers() {
      return this.activeTabIndex === 2 ? [
        {
          text: 'Status',
          sortable: false,
          value: 'status',
        },
        {
          text: this.$tc('startDate'),
          value: 'startDate',
          sortable: false,
          iconName: 'date'
        },
        {
          text: 'Amount', value: 'amount', sortable: false,
        },
        {
          text: 'Pool Name', value: 'name', sortable: false,
        },
      ] : [
        {
          text: 'Status',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Type', value: 'transactionType', sortable: false,
        },
        {
          text: 'Date', value: 'createdAt', sortable: false,
        },
        {
          text: 'Amount', value: 'amount', sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getWalletTransaction: 'wallet/getWalletTransaction',
      getStakeTransaction: 'stake/getStakeTransaction',
      getInvestment: 'funds/getInvestment',
    })
  },
  created() {
    this.getWalletTransaction()
    this.getStakeTransaction()
    this.getInvestment()
  }
}
</script>

<style lang="scss" scoped>
.transaction-table {
  padding: 31px 0;

  .transaction-header-container {
    @include flex;
    margin-bottom: 31px;

    .tabs-list {
      @include flex;
      background: #171717;
      border-radius: 16px;
      width: fit-content;
      margin-left: 18px;
      @media only screen and (max-width: 767px) {
        margin-left: 0;
      }

      .item {
        border-radius: 16px;
        font-weight: 400;
        font-size: 14px;
        color: #537090;
        padding: 15px;
        transition: all .4s ease;
        cursor: pointer;

        &.active {
          background: #1B69EB;
          color: white;
        }
      }
    }

    .header-title {
      font-weight: 300;
      font-size: 25px;
      text-transform: capitalize;
      color: #FFFFFF;
      display: block;
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }

  }


  ::v-deep .v-data-table {
    background: #1e1e1e;
    @media only screen and (max-width: 1023px) {
      background: #171717;
    }

    &.v-data-table--mobile {
      tbody {
        @media only screen and (max-width: 599px) {
          display: table-row-group !important;
        }
      }

      .v-data-table-header-mobile {
        display: none;
      }

      .v-data-table__mobile-row__header {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #FFFFFF;
      }

      .v-data-table__mobile-row__cell {
        color: white;

        .title {
          color: white;
        }
      }

      .v-data-table__mobile-table-row {
        background: transparent;
        display: block;
        border: 1px solid rgba(250, 250, 250, .2);
        margin-top: 15px;
        border-radius: 10px;
        height: unset
      }
    }

    .text-start {
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      font-family: "Rubik";
    }

    .v-data-table-header {
      span {
        font-weight: 500;
        font-size: 22px;
        color: #FFFFFF;
        font-family: "Rubik";
        text-align: left;
      }
    }

    tr {
      height: 52px;
      background: #171717;
      @media only screen and (max-width: 1023px) {
        background: #1e1e1e;
      }

      &:hover {
        background: #171717 !important;
        @media only screen and (max-width: 1023px) {
          background: #1e1e1e;
        }
      }

      td, th {
        margin-bottom: 13px;
        border-bottom: 3px solid #1e1e1e !important;
        padding: 15px 30px;
        @media only screen and (max-width: 1023px) {
          border-bottom: 3px solid #171717 !important;
        }

        &:first-child {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }

        &:last-child {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }

      .table-flex {
        @include flex;

        .title {
          margin-left: 10px;
        }
      }
    }

    .v-data-table__empty-wrapper {
      td {
        padding: 50px 0;
        color: white;
        font-size: 22px;
      }
    }

  }
}
</style>