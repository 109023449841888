<template>
  <div class="dashboard-main">
    <div class="up dsh-container">
      <div class="wallet ds-box">
        <!--        <span class="header-title">{{ $tc('balance') }}</span>-->
        <!--        <div class="item-list">-->
        <!--          <div v-for="(item,index) in userBalance" :key="index" class="item">-->
        <!--            <span class="title">{{ item[0] }}</span>:-->
        <!--            <span class="description">{{ item[1] }}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <img src="../../assets/images/wallet_circle.png" class="image" alt="">
        <div class="content">
          <span class="header-title">{{ $tc('balance') }}</span>
          <!--                  <span v-line-clamp="1" :title="balance.walletBalance" class="value">{{ balance.walletBalance }}</span>-->
          <!--                  <span class="currency">DFORT</span>-->
          <div v-for="(item,index) in userBalance" :key="index" class="item">
            <span class="value">{{ item[0] }}</span>:
            <span class="currency">{{ item[1] }}</span>
          </div>
          <div class="blur"></div>
        </div>
      </div>
      <!--      <div v-for="(item,index) in stakePackages"-->
      <!--           :class="{light:index===1}"-->
      <!--           :key="item.id"-->
      <!--           class="stake-box">-->
      <!--        <div class="content">-->
      <!--          <div class="header-container">-->
      <!--            <div class="item">-->
      <!--              <img v-if="item.id===1" src="../../assets/images/stake.png" class="image" alt="">-->
      <!--              <img v-else src="../../assets/images/radiation_clock.png" class="image" alt="">-->
      <!--              <span v-line-clamp="1" :title="item.name" class="title">{{ $tc(item.code) }}</span>-->
      <!--            </div>-->
      <!--            <div class="item">-->
      <!--              <img v-if="item.id===1" src="../../assets/images/stake.png" class="image" alt="">-->
      <!--              <img v-else src="../../assets/images/radiation_clock.png" class="image" alt="">-->
      <!--              <span v-line-clamp="1" :title="item.durationInDays" class="title">{{-->
      <!--                  item.durationInDays-->
      <!--                }} {{ $tc('month') }}</span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <span class="description">{{ $tc(item.code + '_DESCRIPTION') }}</span>-->
      <!--          <div class="bottom">-->
      <!--            <div class="left">-->
      <!--              <div class="item">-->
      <!--                <span class="month">apr</span>-->
      <!--                <img src="../../assets/images/question.png" alt="">-->
      <!--              </div>-->
      <!--              <div class="item">-->
      <!--                <span v-if="item.id===1" class="value">20%-25%</span>-->
      <!--                <span v-else class="value">25%-35%</span>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <router-link class="link" to="/Staking">{{ $tc('stake_now') }}</router-link>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="background"></div>-->
      <!--      </div>-->
      <div class="wallet ds-box">
        <img src="../../assets/images/wallet_circle.png" class="image" alt="">
        <!--        <div class="content">-->
        <!--          <span class="header-title">{{ $tc('total_balance') }}</span>-->
        <!--          <p class="amount">{{ balance.stakeBalance }} <span>DFORT</span></p>-->
        <!--          <div class="blur"></div>-->
        <!--        </div>-->
        <span class="header-title">{{ $tc('total_balance') }}</span>
        <p class="amount">{{ hedgeFundBalance }} <span>DFORT</span></p>
      </div>
      <!--      <div class="balance-box ds-box">-->
      <!--        <div class="content">-->
      <!--          <span class="header-title">{{ $tc('total_balance') }}</span>-->
      <!--          <p class="amount">{{ hedgeFundBalance }}<span>DFORT</span></p>-->
      <!--        </div>-->
      <!--        <div class="background"></div>-->
      <!--      </div>-->
      <!--      <div class="balance-box ds-box">-->
      <!--        <div class="content">-->
      <!--          <span class="header-title">{{ $tc('stake_balance') }}</span>-->
      <!--          <p class="amount">{{ balance.stakeBalance }} <span>DFORT</span></p>-->
      <!--        </div>-->
      <!--        <div class="background"></div>-->
      <!--      </div>-->

      <div class="wallet ds-box">
        <img src="../../assets/images/wallet_circle.png" class="image" alt="">
<!--        <div class="content">-->
<!--          <span class="header-title">{{ $tc('stake_balance') }}</span>-->
<!--          <p class="amount">{{ balance.stakeBalance }} <span>DFORT</span></p>-->
<!--          <div class="blur"></div>-->
<!--        </div>-->
        <span class="header-title">{{ $tc('stake_balance') }}</span>
        <p class="amount">{{ balance.stakeBalance }} <span>DFORT</span></p>
      </div>


      <!--      <div class="dsh-box currency">-->
      <!--        <div class="currency-content">-->
      <!--          <h2 class="header-title">{{ $tc('exchange_rates') }}</h2>-->
      <!--          <div class="currency-item">-->
      <!--            <input type="number" v-model="fortchainValue" placeholder="100" class="input">-->
      <!--            <div class="image-container">-->
      <!--              <div class="dfort-container">-->
      <!--                <img src="../../assets/images/fortchain_new.png" class="image" alt="">-->
      <!--              </div>-->
      <!--              <span class="title">DFORT</span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="currency-item">-->
      <!--            <input type="number" :value="activeCurrencyValue" class="input">-->
      <!--            <div class="image-container">-->
      <!--              <v-select :clearable="false"  v-model="currency" :options="stakeSelectOptions">-->
      <!--                <template v-slot:option="option">-->
      <!--                  <img :src="option.icon" alt="">-->
      <!--                  <span class="title">{{ option.label }}</span>-->
      <!--                </template>-->
      <!--                <template #selected-option="{ label, icon }">-->
      <!--                  <img :src="icon" alt="">-->
      <!--                  <span class="title">{{ label }}</span>-->
      <!--                </template>-->
      <!--              </v-select>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="earning-container dsh-box">-->
      <!--        <SellTokens/>-->
      <!--        &lt;!&ndash;        <img src="../../assets/images/wallet_token.png" class="image" alt="">&ndash;&gt;-->
      <!--        &lt;!&ndash;        <div class="right">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <span class="header-title">{{ $tc('dashboardFondTitle1') }} <br/> {{ $tc('dashboardFondTitle2') }}</span>&ndash;&gt;-->
      <!--        &lt;!&ndash;          <span class="title">{{ overallStats.totalValueLocked }} DFORT</span>&ndash;&gt;-->
      <!--        &lt;!&ndash;          <Button @click.native="goToStake" :title="$tc('hedgeFund')"/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
      <!--      </div>-->
    </div>
    <div class="up dsh-container">
    </div>
    <transaction-table/>
  </div>
</template>

<script>
import TransactionTable from "./TransactionTable";
// import SellTokens from "./SellTokens";
import {mapActions, mapGetters} from "vuex";
// import vSelect from 'vue-select'

export default {
  data() {
    return {
      currency: {},
      stakeSelectOptions: [],
      fortchainValue: 1
    }
  },
  components: {
    TransactionTable,
    // vSelect,
    // SellTokens
  },
  computed: {
    ...mapGetters({
      stakePackages: 'stake/getStakePackages',
      balance: 'wallet/getBalance',
      overallStats: 'funds/getOverallStats',
    }),
    hedgeFundBalance() {
      return this.balance.fundBalance?.DFORT
    },
    totalBalance() {
      return Number(this.balance.stakeBalance) + Number(this.balance.walletBalance)
    },
    stakeBalanceApr() {
      let bal = Number(this.balance.stakeBalance)
      return bal + bal * 20 / 100
    },
    activeCurrencyValue() {
      return this.fortchainValue / Number(this.currency?.code)
    },
    userBalance() {
      let object = this.balance.balance
      let balance = []

      for (const property in object) {
        balance.push([property, object[property]])
      }

      return balance
    }
  },
  methods: {
    ...mapActions({
      getStakePackages: 'stake/getStakePackages',
      getBalance: 'wallet/getBalance',
      getOverallStats: 'funds/getOverallStats',
    }),
    goToStake() {
      this.$router.push({path: '/hedge-fund'})
    },
    getPrices() {
      this.$axios.get('api/v1/price').then(({data}) => {
        this.stakeSelectOptions = data?.map(item => ({
          label: item.symbol,
          code: item.price,
          icon: require(`@/assets/images/${item.symbol?.toLowerCase()}.png`)
        }));
        this.currency = this.stakeSelectOptions?.find(item => item.label === 'BNB')
      })
    }
  },
  created() {
    this.getStakePackages()
    this.getBalance();
    this.getPrices()
    this.getOverallStats()
  }
}
</script>

<style lang="scss" scoped>
.dashboard-main {
  padding: 0 27px;
  @media only screen and (max-width: 1440px) {
    padding: 0 19px;
  }

  .dsh-container {
    @include flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:first-child {
      margin-bottom: 21px;
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        margin-bottom: 15px;
        align-items: flex-start;
      }
      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    @media only screen and (max-width: 1023px) {
      display: block;
    }
  }

  //.wallet {
  //  padding: 22px 30px;
  //  background: #0F0E15;
  //  border-radius: 15px;
  //  position: relative;
  //  height: 360px;
  //  min-width: 24%;
  //  max-width: 24%;
  //
  //  @media only screen and (max-width: 1800px) and (min-width: 1650px) {
  //    height: 300px;
  //    padding: 40px;
  //  }
  //  @media only screen and (max-width: 1649px) and (min-width: 1441px) {
  //    padding: 30px;
  //    height: 300px;
  //  }
  //  @media only screen and (max-width: 1440px) and (min-width: 1366px) {
  //    padding: 25px;
  //    height: 260px;
  //  }
  //  @media only screen and (max-width: 1365px) and (min-width: 1024px) {
  //    padding: 20px;
  //    height: 240px;
  //    min-width: calc(50% - 8px);
  //  }
  //  @media only screen and (max-width: 1023px) {
  //    max-width: unset;
  //  }
  //  @media only screen and (max-width: 767px) {
  //    height: 280px;
  //    padding: 30px;
  //  }
  //
  //  .header-title {
  //    font-style: normal;
  //    font-weight: normal;
  //    font-size: 25px;
  //    color: #FFFFFF;
  //  }
  //
  //  .item-list {
  //    margin-top: 20px;
  //
  //    .item {
  //      display: flex;
  //      align-items: center;
  //      color: white;
  //
  //      .description {
  //        color: rgba(250, 250, 250, .6);
  //        margin-left: 5px;
  //      }
  //    }
  //  }
  //}
  .ds-box {
    height: 360px;
    min-width: 32%;
    @media only screen and (max-width: 1800px) and (min-width: 1650px) {
      height: 300px;
    }
    @media only screen and (max-width: 1649px) and (min-width: 1441px) {
      height: 300px;
    }
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      height: 260px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      height: 240px;
    }
    @media only screen and (max-width: 1023px) {
      margin-top: 20px;
    }
  }

  .wallet {
    @include center-children;
    flex-direction: column;
    padding: 57px;
    background: #0F0E15;
    border-radius: 15px;
    position: relative;
    height: 360px;
    min-width: 30%;
    @media only screen and (max-width: 1800px) and (min-width: 1650px) {
      height: 300px;
      padding: 40px;
    }
    @media only screen and (max-width: 1649px) and (min-width: 1441px) {
      padding: 30px;
      height: 300px;
    }
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      padding: 25px;
      height: 260px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      padding: 20px;
      height: 240px;
      min-width: 28%;
    }

    .image {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: calc(100% - 70px);
      max-width: 90%;
      object-fit: contain;
      @media only screen and (max-width: 1649px) and (min-width: 1024px) {
        height: calc(100% - 30px);
      }

    }

    .header-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;

      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        font-size: 15px;
      }
    }

    .amount {
      color: white;
      display: flex;
      align-items: center;
      margin-top: 5px;

      span {
        margin-left: 10px;

      }
    }

    .content {
      display: flex;
      align-items: center;
      width: 224px;
      height: 224px;
      flex-direction: column;
      background: radial-gradient(50% 50% at 50% 50%, #4875F4 0%, rgba(30, 30, 30, 0) 100%);
      border: 1px solid #4875F4;
      border-radius: 50%;
      @media only screen and (max-width: 1800px) and (min-width: 1441px) {
        width: 180px;
        height: 180px;
      }
      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        width: 150px;
        height: 150px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        width: 135px;
        height: 135px;;
      }

      .header-title {
        margin-bottom: 10px;
        margin-top: 25px;

      }

      .item {
        display: flex;

      }

      .value {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
      }

      .currency {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #44D7E3;

      }
    }
  }

  .stake-box {
    border-radius: 15px;
    padding: 22px 30px;
    margin-left: 21px;
    height: 360px;
    position: relative;
    background: #0F0E15;
    width: calc(38% - 21px);
    @media only screen and (max-width: 1800px) and (min-width: 1441px) {
      height: 300px;
      padding: 22px;
    }
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      padding: 18px;
      height: 260px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      padding: 15px;
      height: 240px;
      margin-left: 15px;
      min-width: calc(50% - 8px);
      &:nth-child(3) {
        margin-left: 0;
        margin-top: 15px;
      }
    }
    @media only screen and (max-width: 1023px) {
      margin-left: 0;
      margin-top: 15px;
      width: 100%;
    }
    @media only screen and (max-width: 767px) {
      height: 193px;
      padding: 11px;
    }

    &.light {
      .background {
        background: linear-gradient(260.07deg, rgba(67, 222, 226, 0.41) -2.64%, rgba(15, 14, 21, 0) 57.45%);
      }
    }

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      pointer-events: none;
      background: linear-gradient(260.07deg, #1D2541 -2.64%, rgba(15, 14, 21, 0) 57.45%);
      border-radius: 15px;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 28px 36px;
      border-radius: 15px;
      border: 1px solid #4873F5;
      position: relative;
      z-index: 1;
      height: 100%;
      //border-image-source: linear-gradient(180deg, #4873F5 0%, #44C7E6 100%);
      @media only screen and (max-width: 1800px) and (min-width: 1650px) {
        padding: 20px 26px;
      }
      @media only screen and (max-width: 1649px) and (min-width: 1441px) {
        padding: 22px;
      }
      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        padding: 18px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        padding: 0;
        border: 1px solid transparent;
      }
      @media only screen and (max-width: 767px) {
        padding: 11px 20px;
      }

      .header-container {
        @include flex;

        .item {
          @include flex;

          .title {
            margin-left: 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            color: #FFFFFF;
            @media only screen and (max-width: 1800px) and (min-width: 1650px) {
              font-size: 22px;
            }
            @media only screen and (max-width: 1649px) and (min-width: 1441px) {
              font-size: 20px;
            }
            @media only screen and (max-width: 1440px) and (min-width: 1366px) {
              font-size: 18px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
              font-size: 16px;
            }
            @media only screen and (max-width: 767px) {
              font-size: 16px;
              margin-left: 5px;
            }
          }

          img {
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
              width: 20px;
            }
            @media only screen and (max-width: 767px) {
              width: 15px;
            }
          }

          &:last-child {
            margin-left: 15px;
          }
        }
      }

      .description {
        margin-top: 35px;
        font-weight: normal;
        font-size: 25px;
        line-height: 30px;
        text-transform: capitalize;
        color: #D8D8D8;
        max-width: 90%;
        @media only screen and (max-width: 1800px) and (min-width: 1650px) {
          margin-top: 25px;
          font-size: 22px;
        }
        @media only screen and (max-width: 1649px) and (min-width: 1441px) {
          margin-top: 20px;
          font-size: 20px;
        }
        @media only screen and (max-width: 1440px) and (min-width: 1366px) {
          margin-top: 20px;
          font-size: 18px;
          line-height: 22px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          margin-top: 30px;
          font-size: 16px;
          max-width: 100%;
          line-height: 18px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          max-width: 100%;
          line-height: 18px;
          margin-top: 15px;
        }
      }

      .bottom {
        @include space-between;
        align-items: flex-end;
        padding-top: 50px;
        margin-top: 30px;
        border-top: 1px solid rgba(255, 255, 255, 0.16);
        @media only screen and (max-width: 1800px) and (min-width: 1441px) {
          padding-top: 30px;
          margin-top: 20px;
        }
        @media only screen and (max-width: 1440px) and (min-width: 1366px) {
          padding-top: 25px;
          margin-top: 20px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          padding-top: 40px;
          margin-top: 25px;;
        }
        @media only screen and (max-width: 767px) {
          padding-top: 0;
          border-top: unset;
        }

        .left {
          .item {
            @include flex;

            &:last-child {
              margin-top: 10px;
            }

            .month {
              font-weight: normal;
              font-size: 16px;
              color: #FFFFFF;
            }

            .value {
              font-weight: 500;
              font-size: 22px;
              color: #FFFFFF;
              @media only screen and (max-width: 1440px) and (min-width: 1366px) {
                font-size: 18px;
              }
              @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                font-size: 16px;
              }
              @media only screen and (max-width: 767px) {
                font-size: 16px;
              }
            }

            img {
              margin-left: 16px;
            }
          }
        }

        .link {
          font-weight: 500;
          font-size: 22px;
          text-decoration-line: underline;
          text-transform: capitalize;
          color: $base-color;
          @media only screen and (max-width: 1649px) and (min-width: 1441px) {
            font-size: 20px;
          }
          @media only screen and (max-width: 1440px) and (min-width: 1366px) {
            font-size: 18px;
          }
          @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            font-size: 16px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .balance-box {
    border-radius: 15px;
    padding: 27px 0 0 26px;
    position: relative;
    background: #0F0E15;

    @media only screen and (max-width: 1649px) and (min-width: 1441px) {

      padding: 20px 0 0 26px;
    }
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      padding: 20px 0 0 20px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      padding: 20px 0 0 20px;
    }

    &:first-child {
      margin-bottom: 20px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(260.07deg, #1D2541 -2.64%, rgba(15, 14, 21, 0) 57.45%);
      left: 0;
      top: 0;
      pointer-events: none;
      border-radius: 15px;
    }

    .header-title {
      font-weight: normal;
      font-size: 25px;
      text-transform: capitalize;
      color: #FFFFFF;
      @media only screen and (max-width: 1649px) and (min-width: 1366px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        font-size: 18px;
      }
    }

    .amount {
      @include flex;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #FFFFFF;
      margin-top: 7px;
      @media only screen and (max-width: 1649px) and (min-width: 1366px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: $base-color;
        margin-left: 5px;
      }
    }
  }


  .dsh-box {
    width: calc(38% - 21px);
    background: #0F0E15;
    border-radius: 15px;
    margin-left: 21px;
    margin-top: 21px;
    height: 256px;
    @media only screen and (max-width: 1649px) and (min-width: 1441px) {
      height: 250px;
    }
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      height: 250px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      height: 250px;
      min-width: calc(50% - 8px);
      margin-left: 0;
      margin-top: 15px;
      &:last-child {
        margin-left: 15px;
      }
    }
    @media only screen and (max-width: 1023px) {
      width: 100%;
    }
  }

  .earning-container {
    display: flex;
    align-items: flex-start;


    @media only screen and (max-width: 1023px) {
      margin-left: 0;
      margin-top: 15px;
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 377px;
    }


  }

  .dsh-box.currency {
    @include center-children;
    padding: 40px 30px;
    $width: 60px;
    @media only screen and (max-width: 1650px) and (min-width: 1024px) {
      $width: 50px;
    }
    @media only screen and (max-width: 1800px) and (min-width: 1441px) {
      padding: 22px;
    }
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
      padding: 18px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      padding: 15px;
    }
    @media only screen and (max-width: 1023px) {
      margin-left: 0;
      margin-top: 20px;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
      padding: 30px 20px;
    }

    .header-title {
      color: white;
      display: block;
      font-size: 28px;
      @media only screen and (max-width: 1649px) and (min-width: 1366px) {
        font-size: 24px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        font-size: 20px;
      }
    }

    .currency-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 15px;
      position: relative;
      height: 100%;
    }

    .currency-item {
      position: relative;
      width: 100%;
      @include flex;
      margin-top: 25px;

      .image-container {
        @include center-children;
        min-width: 30%;
        max-width: 30%;
        background: rgba(7, 10, 29, .67);
        height: 100%;
        border: 1px solid #1e1e1e;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
          min-width: 40%;
        }
        @media only screen and (max-width: 767px) {
          min-width: 45%;
        }

        .dfort-container {
          @include center-children;
          border: 2px solid #1280A0;
          border-radius: 50%;
          height: 35px;
          width: 35px;

          img {
            width: 11px;
          }
        }

        img {
          height: 30px;
          width: 30px;
          object-fit: contain;
        }

        .title {
          color: white;
          font-size: 18px;
          margin-left: 10px;
        }
      }

      input {
        background: transparent;
        border: 1px solid hsla(0, 0%, 100%, .3);
        height: $width;
        width: 100%;
        font-size: 18px;
        color: white;
        font-weight: bold;
        padding-left: 20px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      ::v-deep .v-select {
        width: 100%;
        height: 100%;

        .vs__dropdown-toggle {
          width: 100%;
          height: 100%;
          border: unset;
          padding: 0;

          .vs__selected-options {
            flex-grow: unset;
            flex-wrap: unset;
            padding: 0;
            flex-basis: unset;
            width: 100%;
            @include center-children;
          }

          .vs__selected {
            @include center-children;
            width: 100%;
            height: 100%;
            border: unset;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #FFFFFF;
            font-family: "Rubik";
            padding: 0;
            margin: 0;
          }

          input {
            display: none;
          }
        }

        .vs__dropdown-menu {
          background: transparent;
          border: 1px solid #1e1e1e;
          padding: 0;
          border-top: unset;

          li {
            @include flex;
            padding: 5px 10px 5px 10px;
            background: #171717;

            &:first-child {
              margin-top: 0;
            }

            &:hover, &.vs__dropdown-option--selected, &.vs__dropdown-option--highlight {
              background: $base-color;

              .title {
                color: #0F0E15;
              }
            }

            .title {
              color: white;
              margin-left: 10px;
            }
          }
        }

        .vs__actions {
          display: none;
        }
      }
    }


  }
}
</style>